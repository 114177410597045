















































































































































































































































































































































































































































import { AxiosResponse } from 'axios';
import { IClient } from '@/interfaces/client';
import { IPagination } from '@/interfaces/pagination';
import { IProject } from '@/interfaces/project';
import { ISpObject, ISpObjectsResponse } from '@/interfaces/spobject';
import Base from '@/mixins/Base.vue';

const component = Base.extend({

    data() {
        return {
            params: {
                active: null,
                name: '',
                sortBy: 'id',
                orderBy: 'desc',
                client: undefined as any | IClient,
                clients: [] as Array<IClient>,
                project: undefined as any | IProject,
                page: 1,
                status: 1,
            },
            selectedObjects: [] as Array<ISpObject>,
            objects: {
                data: [] as Array<ISpObject>,
                meta: {} as IPagination | any,
            } as ISpObjectsResponse,
        };
    },
    mounted() {
        this.getObjects();
    },
    methods: {
        getObjects(page: number = 1): void {
            this.params.page = page;

            this.get<ISpObjectsResponse>('objects', {
                params: {
                    ...this.params,
                    // client_id: this.params.client ? this.params.client.id : null,
                    clients: this.params.clients.map((c) => c.id),
                    project_id: this.params.project ? this.params.project.id : null,

                },
            }).then(({ data }) => {
                this.objects = data;
            });
        },

        setSelectedObjectsActive() {
            const objects = [] as Array<ISpObject>;
            this.selectedObjects.forEach((obj: ISpObject) => {
                objects.push({
                    ...obj,
                    active: true,
                });
            });

            this.bulkUpdateObjects(objects).then(() => {
                this.getObjects();
            });
        },
        setSelectedObjectsInactive() {
            const objects = [] as Array<ISpObject>;
            this.selectedObjects.forEach((obj: ISpObject) => {
                objects.push({
                    ...obj,
                    active: false,
                });
            });

            this.bulkUpdateObjects(objects).then(() => {
                this.getObjects();
            });
        },
        bulkUpdateObjects(objects: Array<ISpObject>): Promise<AxiosResponse> {
            return this.put<AxiosResponse>('bulk', {
                model: 'App\\Models\\Objects',
                datas: objects,
            });

            // return this.put<AxiosResponse>('objects/bulk', {
            //     objects,
            // });
        },
    },
});

export default component;
